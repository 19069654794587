import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import { Link } from "gatsby-theme-material-ui"
import { makeStyles } from "@material-ui/core/styles"

const NotFoundPage = ({ data, location }) => {
  const classes = useStyles()
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Navbar
        logo={data.contentfulNavbar}
        company="/"
        location={location}
        phone={data.contentfulCompanyInfo.phone}
      />
      <div classes={classes.notFoundPageContainer}>
        <h1>NOT FOUND</h1>
        <p>Opps! You just hit a route that doesn&#39;t exist... the sadness.</p>
        <p>
          Go back to&nbsp;
          <Link to="/">Home Page</Link>.
        </p>
      </div>
      <Footer />
    </Layout>
  )
}

export default NotFoundPage

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

const useStyles = makeStyles(() => ({
  notFoundPageContainer: {
    padding: "20px",
  },
}))

export const NotFoundPageQuery = graphql`
  {
    contentfulCompanyInfo(name: { eq: "Boom Chicken" }) {
      name
      url
      keywords
      seoDescription
      streetAddress
      cityStateZip
      phone
      googleMaps
      doordash
      postmates
      grubhub
      phone
      businessHours {
        childMarkdownRemark {
          html
        }
      }
    }
    contentfulNavbar(name: { eq: "Boom Chicken" }) {
      id
      name
      logo {
        fluid {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  }
`
